import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageTitle } from '..';
import actions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { apis, request } from '../../httpUtil';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputLabel, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Tab, TextField, Typography, Tabs } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import surveyAnswerReports from './SurveyAnswerReports';
import { useDebounce } from 'use-debounce';
import _ from "lodash";
import { Questions } from './Questions.js';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { answerType } from './answersConfig.js';
import enums from '../../utils/constants'
import constants from '../../utils/constants';
import html2canvas from 'html2canvas';
import * as JsError from '../../JsError.js';
const { wrongAction } = enums;

const { t, numberWithCommas } = utils;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    cardMain: {
        marginBottom: '16px !important',
        padding: '16px !important'
    },
    cardSecondary: {
        marginBottom: '16px !important'
    },
    surveyDetailsMain: {
        alignItems: "center !important",
        mt: ' 8px !important'
    },
    surveyDetailsItems: {
        fontWeight: 'bold !important',
        flex: '5 !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '8px'
    },
    outletsSurveyed: {
        fontWeight: 'bold !important',
        flex: '5 !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginLeft: '10px !important'
    },
    stringType: {
        marginBottom: '8px !important',
        padding: "8px !important",
        backgroundColor: '#A6D8F7 !important',
        flex: '1px !important',
        overflow: 'hidden',
        height: '50px',
        '&.expanded': {
            height: 'auto'
        }
    },
    collapseSection: {
        display: "flex !important",
        justifyContent: "flex-end !important",
        alignItems: "center !important",
        flexWrap: "nowrap !important"
    },
    section: {
        display: 'flex !important',
        alignItems: 'center !important'
    },
    SectionIconMain: {
        display: 'flex !important',
        alignItems: 'center !important',
        gap: '8px !important'
    },
    dtnSectionIconSecondary: {
        display: 'flex !important',
        alignItems: 'center !important',
        marginRight: '15px !important'
    },
    surveyDetailsContainer: {
        marginTop: '8px !important'
    },
    surveyDetailsExpanded: {
        marginTop: '4px !important',
        marginBottom: '10px !important'
    },
    mainHeader: {
        display: 'flex !important',
        justifyContent: 'space-between !important'
    },
    questionsContent: {
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'flex-start !important',
        marginLeft: '16px !important'
    },
    toggleSection: {
        display: 'flex !important',
        justifyContent: 'flex-end !important',
        marginRight: '16px !important'
    },
    clippedText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        cursor: 'pointer'
    },
    fullText: {
        cursor: 'pointer'
    },
    expandIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    cardContainer: {
        display: 'flex',
        alignItems: 'start'
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px'
    },
    icon: {
        marginRight: '6px'
    },
    horizontalCollapse: {
        maxWidth: theme.spacing(7),
        overflowX: 'hidden',
        transition: 'max-width 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    horizontalExpand: {
        maxWidth: '100%',
        transition: 'max-width 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        justifyContent: 'space-between'
    },
    gridItem: (props) => ({
        flex: props.open ? '0 0 240px' : '0 0 0px',
        transition: 'flex 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        overflow: props.open ? 'visible' : 'hidden'
    }),
    paperBox: {
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        overflow: 'hidden'
    },
    transitionBox: {
        flexGrow: 1,
        flexShrink: 1,
        transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
    },
    paddingBox: {
        padding: '1px !important'
    },
    resultsMainSectionHeader: {
        textTransform: 'none !important',
        color: 'black !important',
        fontWeight: 'bold !important'
    },
    resultsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftGroup: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'flex-start'
    },
    rightGroup: {
        display: 'flex',
        alignItems: 'center'
    },
    filterButton: {
        marginRight: '16px !important'
    },
    fullWidth: {
        width: "100%"
    },
    assortment: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 'bold'
    },
    assortmentDetails: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 'normal'
    },
    tabFont: {
        textTransform: 'none !important',
        fontSize: '16px !important'
    },
    mainMenu: (props) => ({
        overflowY: 'auto',
        overflowX: 'hidden !important',
        maxHeight: props.ITEM_HEIGHT * 4.5,
        maxWidth: props.ITEM_HEIGHT * 6
    }),
    mainBtnMenu: {
        display: 'flex !important',
        justifyContent: 'space-between !important',
        position: 'sticky !important',
        bottom: 0,
        backgroundColor: 'white !important',
        zIndex: 1,
        borderTop: '1px solid #e0e0e0 !important',
        padding: '6px !important'
    },
    menuBtnRight: {
        flex: 1,
        marginRight: '2px !important'
    },
    menuBtnLeft: {
        flex: 1,
        marginLeft: '2px !important'
    },
    outletsMainBtnMenu: {
        display: 'flex !important',
        justifyContent: 'space-between !important',
        position: 'sticky !important',
        bottom: 0,
        backgroundColor: 'white !important',
        zIndex: 1,
        borderTop: '1px solid #e0e0e0 !important'

    }
}))

const getFrequencyType = (val) => {
    switch (val) {
        case "V":
            return "At every visit";
        case "O":
            return "Every outlet only once";
        case "W":
            return "Once per week";
        case "M":
            return "Once per month";
        case "Q":
            return "Once per quarter";
        case "Y":
            return "Once per Year";
        default:
            return "";
    }
}
const possibleFormats = [
    "DD/MM/YYYY", "D/MM/YYYY", "DD-MMM-YYYY", "D-MMM-YYYY", "DD.MM.YYYY", "D.M.YYYY",
    "YYYY-MM-DD", "YYYY/MM/DD", "YYYY.M.DD",
    "MM/DD/YYYY", "MMM DD, YYYY", "MMMM DD, YYYY",
    "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss",
    "D-MMM-YY", "DD/MMM/YYYY"
];

const defaultComparator = "Market";



const ExportMenuItem = React.memo(({ handleExport, type, contentType, isPivotExport = false }) => {
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    return (
        <MenuItem
            onClick={() => {
                handleExport({ type, contentType, isPivotExport });
            }}
            data-type={type}
            data-content-type={contentType}
            data-is-pivot-export={isPivotExport}
        >
            {t("Export", tOpts)} {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
        </MenuItem>
    );
});

const CustomExportButton = React.memo((props) => {
    const exportTypes = [
        { type: "csv", contentType: "text/csv" },
        { type: "xlsx", contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
        { type: "xml", contentType: "text/xml" },
        { type: "html", contentType: "text/html" },
        { type: "json", contentType: "application/json" },
        { type: "pdf", contentType: "application/pdf" }
    ];
    return (
        <div {...props}>
            {exportTypes.map((exportType) => (
                <ExportMenuItem
                    key={exportType.type}
                    handleExport={() => props.handleExport(exportType)}
                    type={exportType.type}
                    contentType={exportType.contentType}
                    isPivotExport={exportType.isPivotExport}
                />
            ))}
        </div>
    );
});

const FilterCollapseItem = ({ name, classes, children }) => {
    const [expanded, setExpanded] = useState(false);
    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };
    const isPrintClass = expanded ? 'contentToPrint' : '';
    const isPrintVariant = expanded ? 'print' : 'no-print';
    return (
        <Accordion
            className={`${classes.cardMain} ${isPrintClass}`}
            variant={isPrintVariant}
            expanded={expanded}
            onChange={handleAccordionChange}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${name}-content`}
                id={`${name}-id`}
            >
                <Typography className={classes.surveyDetailsItems}>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};


const MoreActions = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    return (
        <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 0.5px 0.5px rgba(0,0,0,0.1))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    }
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem
                onClick={handleMenuClose}
            // className={classes.menuItem}
            >
                <img className={`accordion-export-icon`} src={require(`../../assets/images/excel.png`)} style={{ width: "20%", height: "auto", margin: '0 auto' }} alt={"Export"} />
                {/* {t(``, tOpts)} */}
            </MenuItem>
            {/* Hiding for now - To be added postMVP */}
            {/* <MenuItem onClick={handleMenuClose} className={classes.menuItem}>
                <AccountTreeIcon className={classes.icon} />
                View Data
            </MenuItem>
            <MenuItem onClick={handleMenuClose} className={classes.menuItem}>
                <PrintIcon className={classes.icon} />
                Print
            </MenuItem> */}
        </Menu>
    );
};
const Answers = ({ answers, ...props }) => {
    if (!answers || answers?.length === 0) {
        return  <Grid container justifyContent={'center'}>
        <Grid item justifyContent={'center'}>
            <Typography variant="body1" align="center">
                {constants.noDataText}
            </Typography>
        </Grid>
    </Grid>;
    }
    const displayedQuestionnaireIds = new Set();
    return <>
        {answers.map((answer, index) => {
            const shouldDisplayQuestionnaireName = !displayedQuestionnaireIds.has(answer.QuestionnaireId);
            if (shouldDisplayQuestionnaireName) {
                displayedQuestionnaireIds.add(answer.QuestionnaireId);
            }
            return (
                <Answer
                    key={answer.id}
                    answer={answer}
                    props={props}
                    index={answer.questionIndex}
                    shouldDisplayQuestionnaireName={shouldDisplayQuestionnaireName}
                />
            );
        })}
    </>
}

const Answer = ({ answer, index, shouldDisplayQuestionnaireName, props }) => {
    if (!answer) {
        return null;
    }
    const { classes, tabValues, utils, handleTabChange, handleMenuOpen, handleToggleChange, toggleStates, t, tOpts, checkSentenceLength, handleExpandClick, expandedText, selectedComparatorCategory, lookupData, showImages, handleViewChange, comparatorSelected, displayNameMapping } = props;
    const format = utils.systemDateTimeFormat(true);
    const typesWithDynamicOptions = ["Dropdown", "checkbox", "radio"];
    const textQuestionTypes = ['text', 'textarea'];
    const validatedFromAsset = (answer?.type === 'barcode') && !answer?.validateFromAsset;
    const analyticsQuestionTypes = ['file', 'multiFile', 'scene'];
    const questionTypeArray = ['text', 'textarea', 'checkbox', 'number', 'file', 'multiFile', 'scene'];
    const textTotalCount = textQuestionTypes.includes(answer.type) || (typesWithDynamicOptions.includes(answer.type) && answer.dynamicOptions) ? answer.totalCount : 0;
    let textDistinctCount;
    let isCustomOption = answer.isCustomOption;
    const radioCheckboxQuestionTypes = ['radio', 'checkbox'];
    const dateQuestionType = ['date'];
    const numberQuestionType = ['number'];
    const barcodeQuestionType = ['barcode'];
    if (textQuestionTypes.includes(answer.type)) {
        const uniqueTextValues = new Set(answer.sortedText.map(item => item[0]));
        const uniqueTextArray = [...uniqueTextValues];
        textDistinctCount = uniqueTextArray.length;
    }
    if (numberQuestionType.includes(answer.type) && (answer.series?.data?.length ?? 0) === 0) return null;
    if (radioCheckboxQuestionTypes.includes(answer.type) && ((answer.series?.[0]?.data?.length ?? 0) === 0) && !isCustomOption) return null;
    if (textQuestionTypes.includes(answer.type) && (Object.entries(answer.sortedText ?? {}).length === 0)) return null;
    if (dateQuestionType.includes(answer.type) && (Object.entries(answer.sortedDateData ?? {}).length === 0)) return null;
    if (analyticsQuestionTypes.includes(answer.type) && (answer.imageData?.length ?? 0) === 0) return null;
    if (barcodeQuestionType.includes(answer.type) && ((answer.series?.[0]?.data?.length ?? 0) === 0)) return null;
    const questionWithDynamicOptions = Boolean(typesWithDynamicOptions.includes(answer.type) && answer.dynamicOptions);
    let minDate = answer.sortedDateData.minDate || answer.comparatorData.minDate;
    let maxDate = answer.sortedDateData.maxDate || answer.comparatorData.maxDate;
    const top10 = 10;
    const bottom10 = 10;
    let AnswerDetail = answerType[answer.type]({ answer, utils, toggleStates, top10, bottom10, questionWithDynamicOptions, validatedFromAsset, textTotalCount, textDistinctCount, isCustomOption, format, classes, checkSentenceLength, handleExpandClick, expandedText, selectedComparatorCategory, lookupData, tab: tabValues[answer.id || '1'], showImages, displayNameMapping, comparatorSelected });

    return (
        <div className={`${classes.cardSecondary} pagebreakClass`}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                {shouldDisplayQuestionnaireName && (
                    <div>{answer.QuestionnaireName}</div>
                )}
                <TabContext value={tabValues[answer.id] || '1'}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValues[answer.id] || '1'} data-questionid={answer.id} onChange={handleTabChange} aria-label='main tabs'>
                            <Tab label={t("Total", tOpts)} value="1" />
                            <Tab label={t("Comparison", tOpts)} value="2" />
                        </Tabs>
                    </Box>
                    <TabPanel value="1">
                        <Questions
                            question={answer}
                            answer={answer.answer}
                            index={index}
                            classes={classes}
                            handleMenuOpen={handleMenuOpen}
                            moreActions={<MoreActions />}
                            t={t}
                            tOpts={tOpts}
                            toggleStates={toggleStates}
                            handleToggleChange={handleToggleChange}
                            answerDetail={AnswerDetail}
                            validatedFromAsset={validatedFromAsset}
                            questionTypeArray={questionTypeArray}
                            analyticsQuestionTypes={analyticsQuestionTypes}
                            textTotalCount={textTotalCount}
                            textDistinctCount={textDistinctCount}
                            questionWithDynamicOptions={questionWithDynamicOptions}
                            minDate={minDate}
                            maxDate={maxDate}
                            handleViewChange={handleViewChange}
                            tabValue={tabValues[answer.id] || '1'}
                            showImages={showImages}  
                            format={format}                          
                        />
                    </TabPanel>
                    < TabPanel value="2">
                        <Questions
                            question={answer}
                            answer={answer.answer}
                            index={index}
                            classes={classes}
                            handleMenuOpen={handleMenuOpen}
                            moreActions={<MoreActions />}
                            t={t}
                            tOpts={tOpts}
                            toggleStates={toggleStates}
                            handleToggleChange={handleToggleChange}
                            answerDetail={AnswerDetail}
                            minDate={minDate}
                            maxDate={maxDate}
                            textTotalCount={textTotalCount}
                            textDistinctCount={textDistinctCount}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
        </div >
    );
};

const RenderMenu = ({ lookupData, selectedComparatorCategory, comparatorAnchorEl, handleItemCheckChange, handleAllMainCheckChange, isAllMainChecked, ITEM_HEIGHT, checkedItems, handleApplyClick, handleCloseMenu }) => {
    checkedItems = checkedItems[selectedComparatorCategory] || []
    const itemsToShow = lookupData[selectedComparatorCategory] || [];
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const classes = useStyles({ ITEM_HEIGHT });
    const isAllChecked = useMemo(() => itemsToShow.every(item => checkedItems.includes(item.LookupId)), [checkedItems.length]);

    return (
        <Menu
            anchorEl={comparatorAnchorEl}
            open={Boolean(comparatorAnchorEl)}
            onClose={handleCloseMenu}
            PaperProps={{
                style: {
                    width: ITEM_HEIGHT * 3.5,
                    overflowX: 'hidden !important',
                },
            }}
        >
            <div className={classes.mainMenu}>
                <MenuItem key="all" onClick={handleAllMainCheckChange}>
                    <Checkbox checked={isAllChecked} indeterminate={!isAllChecked && checkedItems.length} />
                    {t('All', tOpts)}
                </MenuItem>
                {itemsToShow.map((item, idx) => (
                    <MenuItem key={idx} data-itemlookupid={item.LookupId} onClick={handleItemCheckChange}>
                        <Checkbox checked={checkedItems.includes(item.LookupId)} />
                        {item.DisplayValue}
                    </MenuItem>
                ))}
            </div>
            <div className={classes.mainBtnMenu}>
                <Button
                    variant="contained"
                    onClick={handleCloseMenu}
                    color="error"
                    className={classes.menuBtnRight}
                >
                    {t('Cancel', tOpts)}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleApplyClick}
                    color="success"
                    className={classes.menuBtnLeft}
                >
                    {t('Apply', tOpts)}
                </Button>
            </div>
        </Menu>
    );
};

const TimeSelectionMenu = ({ handleTimeCloseMenu, timeAnchorEl, ITEM_HEIGHT, handleApplyClick, selectedTime, handleTimeChange }) => {
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const itemsToShow = ["Weekly", "Monthly", "Quarterly"];
    const [compStartDate, setCompStartDate] = useState(null);
    const [compEndDate, setCompEndDate] = useState(null);
    const classes = useStyles({ ITEM_HEIGHT });
    const onDateCreator = (name) => {
        return (date) => {
            const adjustedDate = dayjs(date).hour(12).minute(0).second(0);
            if (name === "compStartDate") {
                setCompStartDate(adjustedDate);
            } else if (name === "compEndDate") {
                setCompEndDate(adjustedDate);
            }
        };
    };
    const onStartDateChange = onDateCreator('compStartDate');
    const onEndDateChange = onDateCreator('compEndDate');
    const makeHandleTimeChange = (item) => () => handleTimeChange(item);
    return (
        <Menu
            anchorEl={timeAnchorEl}
            open={Boolean(timeAnchorEl)}
            onClose={handleTimeCloseMenu}
            PaperProps={{
                style: {
                    width: ITEM_HEIGHT * 3.5,
                    overflowX: 'hidden !important',
                },
            }}
        >
            <div className={classes.mainMenu}>
                <MenuItem>
                    <Typography >
                        <DatePicker
                            label={t("Start Date", tOpts)}
                            name="compStartDate"
                            onChange={onStartDateChange}
                            value={compStartDate}
                            slotProps={{ textField: { size: 'small' } }}
                            format={utils.systemDateTimeFormat(true)}
                        />
                    </Typography>
                </MenuItem>
                <MenuItem>
                    <Typography >
                        <DatePicker
                            label={t("End Date", tOpts)}
                            name="compEndDate"
                            onChange={onEndDateChange}
                            value={compEndDate}
                            slotProps={{ textField: { size: 'small' } }}
                            format={utils.systemDateTimeFormat(true)}
                        />
                    </Typography>
                </MenuItem>
                {itemsToShow.map((item, idx) => (
                    <MenuItem key={idx} onClick={makeHandleTimeChange(item)}>
                        <Checkbox checked={selectedTime === item} />
                        {item}
                    </MenuItem>
                ))}
            </div>
            <div className={classes.mainBtnMenu}>
                <Button
                    variant="contained"
                    onClick={handleTimeCloseMenu}
                    color="error"
                    className={classes.menuBtnRight}
                >
                    {t('Cancel', tOpts)}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleApplyClick}
                    color="success"
                    className={classes.menuBtnLeft}
                >
                    {t('Apply', tOpts)}
                </Button>
            </div>
        </Menu>
    );
};


const AnalysisDetails = React.memo(() => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isAllChecked, setIsAllChecked] = useState(true);
    const [checkedOutlets, setCheckedOutlets] = useState({});
    const [toggleStates, setToggleStates] = useState({});
    const [expandedText, setExpandedText] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [locationId, setLocationId] = useState();
    const [isFilter, setIsFilter] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedTerm] = useDebounce(searchTerm, 1000);
    const [anchorElP, setAnchorElP] = React.useState(false);
    const portalCombos = useSelector(state => state.appReducer.comboData) || {};
    const reportingCombos = useSelector(state => state.appReducer.resultAnalysisComboData) || {};
    const { LocationType: Channels, LocationClassification: Classification, State } = portalCombos;
    const { Market, City, Distributor, Users } = reportingCombos;
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const classes = useStyles({ open });
    const [isClient, setIsClient] = useState(false);
    const [filterModel, setFilterModel] = useState({
        MarketId: null,
        LocationTypeId: null,
        ClassificationId: null
    });
    const [tempFilterModel, setTempFilterModel] = useState({
        MarketId: null,
        LocationTypeId: null,
        ClassificationId: null
    });
    const [isAllMainChecked, setIsAllMainChecked] = useState(true);
    const [tabValues, setTabValues] = useState({});
    const Time = [];
    const Region = [];
    const Account = [];
    const lookupData = {
        Market: Market,
        LocationType: Channels,
        LocationClassification: Classification,
        User: Users,
        City: City,
        State: State,
        Time: Time,
        Distributor: Distributor,
        Region: Region,
        Account: Account
    };
    const displayNameMapping = {
        Market: "Market",
        LocationType: "Channel",
        LocationClassification: "Classification",
        User: "User",
        City: "City",
        State: "State",
        Time: "Time",
        Distributor: "Distributor",
        Region: "Region",
        Account: "Account"
    };
    const [selectedComparatorCategory, setSelectedComparatorCategory] = useState(defaultComparator);
    const ITEM_HEIGHT = 70;
    const [comparatorAnchorEl, setComparatorAnchorEl] = useState(null);
    const [timeAnchorEl, setTimeAnchorEl] = useState(null);
    const [checkedItems, setCheckedItems] = useState({ [defaultComparator]: [] });
    const [appliedCheckedItems, setAppliedCheckedItems] = useState(null);
    const [selectedTime, setSelectedTime] = useState("");
    const [tabValue, setTabValue] = useState("1");
    const [showImages, setShowImages] = useState({});
    const [comparatorSelected, setComparatorSelected] = useState("Market");
    const [appliedComparator, setAppliedComparator] = useState(defaultComparator);
    const initialLoadRef = useRef(true);
    const [isClearingFilter, setIsClearingFilter] = React.useState(false);
    const previousSearchedTermRef = useRef(searchedTerm);

    useEffect(() => {
        const categoryItems = lookupData[defaultComparator];
        let newCheckedItems = [];
        if (categoryItems && categoryItems.length > 0) {

            newCheckedItems = categoryItems.map(item => item.LookupId);
            setCheckedItems(prev => ({ ...prev, [defaultComparator]: newCheckedItems }));
        }
    }, [reportingCombos, portalCombos]);
    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
    };
    const handleCloseMenu = () => {
        setCheckedItems(utils.deepCloneObject(appliedCheckedItems));
        setComparatorAnchorEl(null);
        setSelectedComparatorCategory(appliedComparator);
    };

    const handleCloseTimeMenu = () => {
        setTimeAnchorEl(null);
    };

    const handleComparatorCategoryChange = useCallback((event) => {
        const category = event.target.value;
        const data = lookupData[category] || [];
        const isTime = category === 'Time';
        if (!data || data.length === 0 && !isTime) {
            event.preventDefault();
            return;
        }
        setSelectedComparatorCategory(category);
        if (!(category in checkedItems)) {
            const selectCheckedItems = category in checkedItems ? checkedItems[category] : data.map(entry => entry.LookupId);
            setCheckedItems(prev => ({ ...prev, [category]: selectCheckedItems }));
            setIsAllMainChecked(selectCheckedItems.length === data.length);
        } else if (appliedComparator !== category) {
            setCheckedItems(prev => ({ ...prev, [category]: data.map(entry => entry.LookupId) }));
            setIsAllMainChecked(true);
        }

        if (isTime) {
            setTimeAnchorEl(event.target);
        }
        setComparatorAnchorEl(event.target);
    }, [lookupData, checkedItems]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
        const tabsWithQuestionId = document.querySelectorAll('[data-questionid]');
        const questionIds = Array.from(tabsWithQuestionId).map(tab => tab.getAttribute('data-questionid'));
        questionIds.forEach(questionId => {
            setTabValues(prev => ({ ...prev, [questionId]: newValue }));
        });

    }

    const handleOutletsApplyClick = () => {
        fetchSurveyAnalysis();
    };

    const handleOutletsCloseMenu = () => {
        setIsAllChecked(true);
        const initialChecks = {};
        const newLocationIds = [];
        data?.outlets?.map(outlet => {
            initialChecks[outlet.LocationId] = true;
            newLocationIds.push(outlet.LocationId);
        });
        fetchSurveyAnalysis(null, null, false, newLocationIds);
    };

    const handleTabChange = (event, newValue) => {
        const questionId = event.target.closest('[data-questionid]').getAttribute('data-questionid');
        setTabValues(prev => ({ ...prev, [questionId]: newValue }));
    };

    const handleAllMainCheckChange = useCallback((event) => {
        const isChecked = event.target.checked;
        setIsAllMainChecked(isChecked);
        if (isChecked) {
            const allLookupIds = lookupData[selectedComparatorCategory]?.map(item => item.LookupId);
            setCheckedItems(prevState => ({ ...prevState, [selectedComparatorCategory]: allLookupIds }));
        } else {
            setCheckedItems(prevState => ({ ...prevState, [selectedComparatorCategory]: [] }));
        }
    }, [selectedComparatorCategory, lookupData]);

    const handleItemCheckChange = useCallback((event) => {
        const itemLookupId = parseInt(event.currentTarget.getAttribute('data-itemlookupid'));
        let newCheckedItems = checkedItems[selectedComparatorCategory] || [];
        if (newCheckedItems.includes(itemLookupId)) {
            newCheckedItems = newCheckedItems.filter(id => id !== itemLookupId);
        } else {
            newCheckedItems.push(itemLookupId);
        }
        setCheckedItems(prev => ({ ...prev, [selectedComparatorCategory]: newCheckedItems }));
        setIsAllMainChecked(newCheckedItems.length === lookupData[selectedComparatorCategory].length);
    }, [checkedItems, lookupData, selectedComparatorCategory]);


    const handleApplyClick = () => {
        fetchSurveyAnalysis();
        setComparatorAnchorEl(null);
        setComparatorSelected(comparatorAnchorEl.value);
        setTimeAnchorEl(null);
        setAppliedComparator(selectedComparatorCategory);
    };

    useEffect(() => {
        utils.getAnalysisComboData();
    }, []);

    const handleClick = (event) => {
        setAnchorElP(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElP(false);
    };
    const handleToggleDrawer = () => {
        setOpen(!open);
    };


    const handleExpandClick = (questionId, answerKey) => {
        setExpandedText(prevState => ({
            ...prevState,
            [questionId]: {
                ...prevState[questionId],
                [answerKey]: !prevState[questionId]?.[answerKey]
            }
        }));
    };

    const handleToggleChange = (event, newValue, questionId) => {
        if (newValue !== null) {
            setToggleStates(prevState => ({ ...prevState, [questionId]: newValue }));
        }
    };

    const handleViewChange = (event, newValue, questionId) => {
        if (newValue !== null) {
            setShowImages(prevState => ({ ...prevState, [questionId]: newValue ? constants.imagesText : constants.analysisText }));
        }
    };

    const handleAllCheckChange = useCallback((event) => {
        const isChecked = event.target.checked;
        setIsAllChecked(isChecked);
        setIsFilter(true);
        const updatedChecks = {};
        data.outlets?.forEach((outlet) => {
            updatedChecks[outlet.LocationId] = isChecked;
        });
        setCheckedOutlets(isChecked ? updatedChecks : {});
        setLocationId(isChecked ? data.outlets?.map((outlet) => outlet.LocationId) : []);
        setFilterModel(prevModel => {
            const newModel = {
                ...prevModel,
                LocationId: isChecked ? data.outlets?.map((outlet) => outlet.LocationId) : null
            };
            return newModel;
        })
        setIsClient(true);
    }, [data]);

    const handleOutletCheckChange = useCallback((id) => {
        setIsFilter(true);
        setCheckedOutlets((prevChecks) => {
            const updatedChecks = { ...prevChecks, [id]: !prevChecks[id] };
            const isAllSelected = data.outlets.every((outlet) => updatedChecks[outlet.LocationId]);
            setIsAllChecked(isAllSelected);

            const newCheckedOutlets = data.outlets?.filter((outlet) => updatedChecks[outlet.LocationId]);
            setFilterModel(prevModel => {
                const newModel = {
                    ...prevModel,
                    LocationId: updatedChecks[id]
                        ? [...(prevModel.LocationId || []), id]
                        : (prevModel.LocationId || []).filter(storedId => storedId !== id)
                };
                return newModel;
            });

            setLocationId(newCheckedOutlets.map((outlet) => outlet.LocationId));
            return updatedChecks;
        });
        setIsClient(true);
    }, [data]);


    const onChangeCreator = (type) => {
        return (event) => {
            const value = event.target.value;
            const validTypes = Object.keys(tempFilterModel);

            if (!validTypes.includes(type)) {
                return;
            }
            setTempFilterModel(prevModel => {
                const newModel = {
                    ...prevModel,
                    [type]: value !== "all" ? value : null,
                };
                return newModel;
            });
            setIsFilter(true);
        }
    };
    const onDateCreator = (name) => {
        return (date) => {
            const adjustedDate = dayjs(date).hour(12).minute(0).second(0);
            if (name === "startDate") {
                setStartDate(adjustedDate);
            } else if (name === "endDate") {
                setEndDate(adjustedDate);
            }
            setIsFilter(true);
        };
    };

    const onClassificationChange = onChangeCreator('ClassificationId');
    const onMarketChange = onChangeCreator('MarketId');
    const onLocationTypeChange = onChangeCreator('LocationTypeId');
    const onStartDateChange = onDateCreator('startDate');
    const onEndDateChange = onDateCreator('endDate');

    function getSelectedDays(value) {
        const selectedDays = [];
        for (let i = 0, iLen = value.length; i < iLen; i++) {
            if (value[i] === '1') {
                selectedDays.push(daysOfWeek[i]);
            }
        }
        return selectedDays.join(', ');
    }

    useEffect(() => {
        const checkedLocations = {};
        data?.outlets?.forEach(outlet => {
            checkedLocations[outlet.LocationId] = true;
        });
        const prevSearchedTerm = previousSearchedTermRef.current;
        if (searchedTerm !== prevSearchedTerm ) {
            setCheckedOutlets(checkedLocations);
        } else {
            setCheckedOutlets((prev) => {
                return Object.values(checkedLocations).length === Object.values(prev).length
                    ? prev
                    : checkedLocations;
            });
        }
        previousSearchedTermRef.current = searchedTerm;
    }, [data, searchedTerm]);
    


    useEffect(() => {
        if (data?.outlets) {
            const newLocationIds = Object.keys(checkedOutlets)
                .filter(id => checkedOutlets[id])
                .map(id => parseInt(id));

            if (newLocationIds.length === 0) return;
            
            if (JSON.stringify(newLocationIds) !== JSON.stringify(locationId)) {
                setLocationId(newLocationIds);
            }
        }
    }, [data, checkedOutlets, locationId]);

    useEffect(() => {
        if (data?.outlets && isAllChecked) {
            const initialChecks = {};
            const newLocationIds = [];
            data?.outlets?.forEach(outlet => {
                initialChecks[outlet.LocationId] = true;
                newLocationIds.push(outlet.LocationId);
            });
            setLocationId(newLocationIds);
            setCheckedOutlets(initialChecks);
        }
    }, [data, isAllChecked]);


    const handleSearchChange = (event) => {
        setIsClient(false);
        const value = event.target.value;
        if (value === "") {
            setTimeout(() => {
                setSearchTerm(value);
            }, 10);
        } else {
            setSearchTerm(value);
        }
    };
    const id = location?.state?.id || location?.pathname.split('/').pop();
    const isValidUrl = utils.isValidIdUrl(id);

    const fetchSurveyAnalysis = async (columns, contentType, isForExport, _locationId) => {
        const isClassification = selectedComparatorCategory === 'LocationClassification';
        const template = null;
        try {
            const reqParams = {
                id: id,
                startDate: startDate,
                endDate: endDate,
                LocationId: _locationId ? _locationId : locationId,
                isFilter: isFilter,
                ...tempFilterModel,
                isClient: isClient,
                isForExport: isForExport,
                contentType: contentType,
                selectedComparatorCategory: isClassification ? displayNameMapping[selectedComparatorCategory] : selectedComparatorCategory,
                comboList: [selectedComparatorCategory],
                checkedComparatorItems: checkedItems[selectedComparatorCategory] || [],
                selectedTime: selectedTime || ""
            }
            if (checkedItems[selectedComparatorCategory]) {
                setAppliedCheckedItems(utils.deepCloneObject(checkedItems));
            }

            if (searchedTerm) {
                reqParams.searchedTerm = searchedTerm;
                reqParams.isFilter = true;
            }
            let url = `${apis.surveyAnalysis}?isForExport=true&id=${id}&contentType=${contentType || 'xlsx'}`;
            if (contentType) {
                const form = document.createElement("form");
                reqParams.responseType = contentType;
                reqParams.columns = columns;
                reqParams.showExport = true;
                form.setAttribute("method", "POST");
                form.setAttribute("id", "exportForm");
                form.setAttribute("target", "_blank")
                if (template === null) {
                    for (const key in reqParams) {
                        let v = reqParams[key];
                        if (v === undefined || v === null) {
                            continue;
                        } else if (typeof v !== 'string') {
                            v = JSON.stringify(v);
                        }
                        let hiddenTag = document.createElement('input');
                        hiddenTag.type = "hidden";
                        hiddenTag.name = key;
                        hiddenTag.value = v;
                        form.append(hiddenTag);
                    }
                }
                form.setAttribute('action', url);
                document.body.appendChild(form);
                form.submit();
                setTimeout(() => {
                    document.getElementById("exportForm").remove();
                }, 3000)
                return;
            }

            const result = await request({ url: apis.surveyAnalysis, params: { ...reqParams }, history, dispatch, jsonPayload: true, disableLoader: false });
            setData(result?.data);
        } catch (error) {
            JsError.handleGlobalError(t('Error fetching survey analysis:', tOpts), '', 1116, 0, error);
        }
    };


    useEffect(() => {
        if (Object.values(checkedItems).some(items => Array.isArray(items) && items.length) && isValidUrl && initialLoadRef.current) {
            initialLoadRef.current = false;
            fetchSurveyAnalysis();
        }
    }, [checkedItems]);

    useEffect(() => {
        if (!initialLoadRef.current) {
            fetchSurveyAnalysis();
        }
    }, [searchedTerm]);

    useEffect(() => {
        if (isClearingFilter) {
            fetchSurveyAnalysis();
            setIsClearingFilter(false);
        }
    }, [filterModel, isClearingFilter]);

    const onApplyFilterClick = () => {
        setFilterModel({ ...tempFilterModel });
        const initialChecks = {};
        const newLocationIds = [];
        data?.outlets?.map(outlet => {
            initialChecks[outlet.LocationId] = true;
            newLocationIds.push(outlet.LocationId);
        });
        fetchSurveyAnalysis(null, null, false, newLocationIds);
        setIsClient(false);
    };

    const clearFilter = () => {
        const clearedModel = {
            MarketId: null,
            LocationTypeId: null,
            ClassificationId: null
        };
        setFilterModel(clearedModel);
        setTempFilterModel(clearedModel);
        setStartDate(null);
        setEndDate(null);
        setIsAllChecked(true)
        setLocationId(data?.outlets?.map((outlet) => outlet.LocationId));
        setIsFilter(false);
        setIsClearingFilter(true);
    };


    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading={t(data?.surveyMaster?.SurveyName, tOpts)} titleDescription={t(data?.surveyMaster?.SurveyDescription, tOpts)} title={t(data?.surveyMaster?.SurveyName, tOpts)} /> });
    }, [data])

    useEffect(() => {
        let backRoute = location.pathname;
        backRoute = backRoute.split("/");
        backRoute.pop();
        backRoute = backRoute.join("/");
        dispatch({
            type: actions.SET_PAGE_BACK_BUTTON,
            pageBackButton: { status: true, backRoute: backRoute }
        });
        return () => {
            utils.removeBackButton(dispatch);
        }
    }, []);


    const checkSentenceLength = (sentence) => {
        const lettersLen = 100;
        return sentence.length > lettersLen;
    }

    const surveysSurveyedToDate = useMemo(() => {
        return (data?.outlets || []).reduce((accumulator, outlet) => accumulator + (outlet.SurveyCount || 0), 0);
    }, [data?.outlets]);
    const isAllLocations = data?.surveyMaster?.IsAllLocations;
    const isAllUsers = data?.surveyMaster?.IsAllUsers;

    const printDivContent = async () => {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        const elementsToPrint = document.getElementsByClassName('contentToPrint');
        if (!elementsToPrint.length) {
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
            console.error('Elements to print not found');
            return;
        }
        const container = document.createElement('div');
        Array.from(elementsToPrint).forEach(element => {
            const clonedElement = element.cloneNode(true);
            container.appendChild(clonedElement);
        });
        const printFrame = document.createElement('iframe');
        printFrame.style.position = 'absolute';
        printFrame.style.top = '-10000px';
        document.body.appendChild(printFrame);
        const printDocument = printFrame.contentWindow.document;
        printDocument.open();
        printDocument.write('<html><head><title>Print Preview</title></head><body>');
        const printCSS = `
            @page { margin: 20mm; }
            body { font-size: 10px; margin: 0; padding: 0; }
            .pagebreakClass { page-break-after: always; }
        `;
        Array.from(document.styleSheets).forEach(styleSheet => {
            if (styleSheet.href) {
                const newStyleElement = printDocument.createElement('link');
                newStyleElement.rel = 'stylesheet';
                newStyleElement.href = styleSheet.href;
                printDocument.head.appendChild(newStyleElement);
            } else {
                try {
                    const newStyleElement = printDocument.createElement('style');
                    newStyleElement.textContent = Array.from(styleSheet.cssRules)
                        .map(rule => rule.cssText)
                        .join('\n');
                    printDocument.head.appendChild(newStyleElement);
                } catch (e) {
                    console.warn('Could not copy stylesheet:', e);
                }
            }
        });
        const styleElement = printDocument.createElement('style');
        styleElement.textContent = printCSS;
        printDocument.head.appendChild(styleElement);
        printDocument.body.appendChild(container);
        printDocument.close();
        printFrame.onload = () => {
            printFrame.contentWindow.focus();
            printFrame.contentWindow.print();
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
        };
    };

    const handleExport = (config) => {
        const { columns, type, contentType } = config;
        let isForExport = true;
        const expoColumns = {};
        if (type === "pdf") {
            printDivContent();
            return;
        }
        columns.forEach(column => {
            expoColumns[column.field] = {
                field: column.field,
                width: column.width,
                headerName: column.headerName,
                type: column.type,
                keepUTC: column.keepUTC === true
            };
        });

        fetchSurveyAnalysis(expoColumns, contentType, isForExport);
    };

    return (
        <>
            {isValidUrl ? data && data?.answers?.length || (data?.surveyMaster && Object.entries(data?.surveyMaster).length) ? <>
                <FilterCollapseItem classes={classes} name={t('Survey Details', tOpts)} >
                    <Grid container spacing={0} >
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Surveys`, tOpts)}</Typography>
                            <Typography>{t(`Submitted Surveys`, tOpts) + ":" + `${surveysSurveyedToDate || 0}`}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Users`, tOpts)}</Typography>
                            <Typography>{t(`Users Included`, tOpts) + ":" + `${isAllUsers ? data?.users[0]?.Users || 0 : data?.users[0]?.AllAssignedUsers || 0}`} </Typography>
                            <Typography>{t(`Users With Submissions`, tOpts) + ":" + `${data?.users[0]?.SurveyedUsers || 0}`}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Outlets`, tOpts)}</Typography>
                            <Typography>{t(`Outlets Included`, tOpts) + ":" + `${isAllLocations ? data?.outlets[0]?.AllLocations || 0 : data?.outlets[0]?.AllAssignedOutlets || 0}`} </Typography>
                            <Typography>{t(`Outlets Surveyed`, tOpts) + ":" + `${data?.outlets[0]?.OutletsSurveyed || 0}`} </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Start - End`, tOpts)}</Typography>
                            <Typography>
                                {dayjs(data?.surveyMaster?.StartDate).format(utils.systemDateTimeFormat(true))} - {dayjs(data?.surveyMaster?.EndDate).format(utils.systemDateTimeFormat(true))}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Recurrence Period`, tOpts)}</Typography>
                            <Typography>{t(getFrequencyType(data?.surveyMaster?.FrequencyType), tOpts) || ''}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Specific Days`, tOpts)}</Typography>
                            <Typography>{getSelectedDays(data?.surveyMaster?.DefinedDays || [])}</Typography>
                        </Grid>
                    </Grid>
                </FilterCollapseItem>

                <FilterCollapseItem classes={classes} name={t('Filters Set-Up', tOpts)}  >
                    <Grid container spacing={2} className={classes.surveyDetailsExpanded}>
                        <Grid item xs={6} sm={4} md={2}>
                            <DatePicker
                                label={t("Start Date", tOpts)}
                                name="startDate"
                                onChange={onStartDateChange}
                                value={startDate}
                                slotProps={{ textField: { size: 'small' } }}
                                format={utils.systemDateTimeFormat(true)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <DatePicker
                                label={t("End Date", tOpts)}
                                name="endDate"
                                value={endDate}
                                onChange={onEndDateChange}
                                slotProps={{ textField: { size: 'small' } }}
                                format={utils.systemDateTimeFormat(true)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel >{t("Market", tOpts)}</InputLabel>
                                <Select label={t("Market", tOpts)} value={tempFilterModel.MarketId || 'all'} onChange={onMarketChange}>
                                    <MenuItem value="all">{t('All', tOpts)}</MenuItem>
                                    {Market?.map((market, key) => (
                                        <MenuItem key={market?.LookupId} value={market.LookupId}>
                                            {t(`${market?.DisplayValue} `, tOpts)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel >{t("Channel", tOpts)}</InputLabel>
                                <Select label={t("Channel", tOpts)} value={tempFilterModel.LocationTypeId || 'all'} onChange={onLocationTypeChange}>
                                    <MenuItem value="all">{t('All', tOpts)}</MenuItem>
                                    {Channels?.map((channel, key) => (
                                        <MenuItem key={channel?.LookupId} value={channel.LookupId}>
                                            {t(`${channel?.DisplayValue} `, tOpts)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel >{t("Classification", tOpts)}</InputLabel>
                                <Select label={t("Classification", tOpts)} value={tempFilterModel.ClassificationId || 'all'} onChange={onClassificationChange}>
                                    <MenuItem value="all">{t('All', tOpts)}</MenuItem>
                                    {Classification?.map((classification, key) => (
                                        <MenuItem key={classification?.LookupId} value={classification.LookupId}>
                                            {t(`${classification?.DisplayValue} `, tOpts)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" color="primary" onClick={onApplyFilterClick} className={classes.filterButton} > {t("Filter", tOpts)}</Button>
                            <Button variant="contained" color="primary" onClick={clearFilter} className={classes.filterButton}> {t("Clear", tOpts)}</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.surveyDetailsExpanded}>
                        {/* Commenting out to first have completely working filters */}
                        {/* <Grid item xs={6} sm={4} md={2}>
                                <FormControl variant="outlined" fullWidth size="small">
                                    <InputLabel >{t("Outlet Group", tOpts)}</InputLabel>
                                    <Select label={t("Outlet Group", tOpts)} value={filterModel.outletGroups} onChange={(e) => onChange(e, "outletGroups")}>
                                        <MenuItem value="all">All</MenuItem>
                                        {data?.outletGroup?.map((outlet, key) => (
                                            <MenuItem key={key} value={outlet?.LocationGroupId}>
                                                {t(`${outlet?.GroupName} `, tOpts)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={4} md={2}>
                                <FormControl variant="outlined" fullWidth size="small">
                                    <InputLabel >{t("User Group", tOpts)}</InputLabel>
                                    <Select label={t("User Group", tOpts)} value={filterModel.userGroups} onChange={(e) => onChange(e, "userGroups")}>
                                        <MenuItem value="all">All</MenuItem>
                                        {data?.userGroup?.map((user, key) => (
                                            <MenuItem key={key} value={user.UserGroupId}>
                                                {t(`${user?.GroupName} `, tOpts)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid> */}
                    </Grid>
                </FilterCollapseItem>

                <FilterCollapseItem classes={classes} name={t('Comparator Set-Up', tOpts)} >
                    <Grid container spacing={2} className={classes.surveyDetailsExpanded}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" variant="outlined" fullWidth size="small">
                                <RadioGroup
                                    row
                                    aria-label="category-options"
                                    name="categoryOptions"
                                    value={appliedComparator}
                                    onChange={handleComparatorCategoryChange}
                                    onClick={handleComparatorCategoryChange}
                                >
                                    <Grid container spacing={2}>
                                        {Object.keys(displayNameMapping).map((category, key) => {
                                            const data = lookupData[category] || [];
                                            const isTime = category === 'Time';
                                            const disabled = isTime ? false : (!data || data.length === 0);
                                            return (
                                                <Grid item xs={12} sm={6} md={3} key={key}>
                                                    <FormControlLabel
                                                        value={category}
                                                        control={<Radio size="small" />}
                                                        label={t(displayNameMapping[category], tOpts)}
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </FilterCollapseItem>
                {selectedComparatorCategory === 'Time' ? (
                    <TimeSelectionMenu
                        handleTimeCloseMenu={handleCloseTimeMenu}
                        timeAnchorEl={timeAnchorEl}
                        ITEM_HEIGHT={ITEM_HEIGHT}
                        handleCloseMenu={handleCloseTimeMenu}
                        handleApplyClick={handleApplyClick}
                        selectedTime={selectedTime}
                        handleTimeChange={handleTimeChange}
                    />
                ) : (
                    <RenderMenu
                        lookupData={lookupData}
                        selectedComparatorCategory={selectedComparatorCategory}
                        comparatorAnchorEl={comparatorAnchorEl}
                        setComparatorAnchorEl={setComparatorAnchorEl}
                        handleItemCheckChange={handleItemCheckChange}
                        handleAllMainCheckChange={handleAllMainCheckChange}
                        isAllMainChecked={isAllMainChecked}
                        checkedItems={checkedItems}
                        ITEM_HEIGHT={ITEM_HEIGHT}
                        handleApplyClick={handleApplyClick}
                        handleCloseMenu={handleCloseMenu}
                    />
                )}
                <Card className={classes.cardMain}>
                    <Grid container direction="row" className={classes.gridContainer}>
                        <Grid item xs={open ? 2 : 0} className={classes.gridItem}>
                            <Box component={Paper} elevation={3} className={classes.paperBox}>
                                <Box className={classes.flexContainer}>
                                    <Typography className={classes.outletsSurveyed}>{t(`Outlets Surveyed`, tOpts)}</Typography>
                                </Box>
                                <Grid item xs>
                                    <Card>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder={`Search Outlets`}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        size="small"
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset" size="small">
                                                        <FormGroup>
                                                            {
                                                                !searchTerm && (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={isAllChecked}
                                                                                onChange={handleAllCheckChange}
                                                                            />
                                                                        }
                                                                        label={t('All', tOpts)}
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                data?.outlets.sort((a, b) => {
                                                                    if (a.Name < b.Name) return -1;
                                                                    if (a.Name > b.Name) return 1;
                                                                    return 0;
                                                                }).map((outlet, key) => (
                                                                    <FormControlLabel
                                                                        key={outlet?.LocationId}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={checkedOutlets[outlet?.LocationId] || false}
                                                                                onChange={() => handleOutletCheckChange(outlet?.LocationId)}
                                                                            />
                                                                        }
                                                                        label={`${outlet.Name} (${outlet.SurveyCount})`}
                                                                    />
                                                                ))
                                                            }
                                                        </FormGroup >
                                                    </FormControl >
                                                    <div className={classes.outletsMainBtnMenu} >
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleOutletsCloseMenu}
                                                            color="error"
                                                            className={classes.menuBtnRight}
                                                        >
                                                            {t('Cancel', tOpts)}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleOutletsApplyClick}
                                                            color="success"
                                                            className={classes.menuBtnLeft}
                                                        >
                                                            {t('Apply', tOpts)}
                                                        </Button>
                                                    </div>
                                                </Grid >
                                            </Grid >
                                        </CardContent >
                                    </Card >
                                </Grid >
                            </Box >
                        </Grid >
                        <Grid item xs={open ? 10 : 12} className={classes.gridItem}>

                            <TabContext value={tabValue}>
                                <Box className={classes.transitionBox}>
                                    <Box className={classes.paddingBox}>
                                        <div className={classes.resultsContainer}>
                                            <div className={classes.leftGroup}>
                                                <Button
                                                    variant="bold"
                                                    onClick={handleToggleDrawer}
                                                    startIcon={open ? <MenuOpenIcon /> : <MenuIcon />}
                                                    sx={{ marginTop: 1 }}
                                                ></Button>
                                                <TabList onChange={handleChangeTab} aria-label="comparator tabs" >
                                                    <Tab label={t(`Results and Charts`, tOpts)} value="1" className={classes.tabFont} />
                                                    <Tab label={t(`Comparison View`, tOpts)} value="2" className={classes.tabFont} />
                                                </TabList>
                                            </div>
                                            <div className={classes.rightGroup}>
                                                <Typography variant="body1" sx={{ marginRight: 2 }}>{t(`${displayNameMapping[comparatorSelected] || 'Market'}`, tOpts)}</Typography>
                                                <Typography variant="body1" sx={{ marginRight: 2 }}>{t(`Export`, tOpts)}</Typography>
                                                <img
                                                    className={`accordion-export-icon cursor-pointer `}
                                                    src={require(`../../assets/images/excel.png`)}
                                                    height='35'
                                                    alt={t(`Export`, tOpts)}
                                                    onClick={handleClick}
                                                    onKeyDown={handleClick}
                                                />
                                                <Menu
                                                    anchorEl={anchorElP}
                                                    open={Boolean(anchorElP)}
                                                    onClose={handleClose}
                                                >
                                                    <CustomExportButton handleExport={(exportType) => handleExport({ ...surveyAnswerReports, ...exportType })} />
                                                </Menu>
                                            </div>
                                        </div>
                                    </Box>
                                    <Card className={`${classes.cardSecondary} contentToPrint`} variant="print">
                                        <Answers
                                            answers={data.answers}
                                            classes={classes}
                                            tabValues={tabValues}
                                            utils={utils}
                                            handleTabChange={handleTabChange}
                                            handleMenuOpen={handleMenuOpen}
                                            handleToggleChange={handleToggleChange}
                                            toggleStates={toggleStates}
                                            t={t}
                                            tOpts={tOpts}
                                            checkSentenceLength={checkSentenceLength}
                                            handleExpandClick={handleExpandClick}
                                            expandedText={expandedText}
                                            selectedComparatorCategory={selectedComparatorCategory || 'Market'}
                                            lookupData={lookupData}
                                            handleChangeTab={handleChangeTab}
                                            showImages={showImages}
                                            handleViewChange={handleViewChange}
                                            displayNameMapping={displayNameMapping}
                                            comparatorSelected={comparatorSelected}
                                        />
                                    </Card >
                                </Box >
                            </TabContext >
                        </Grid >
                    </Grid >
                </Card >
            </> :
                <div>No data available.</div>
                : <div>{t(wrongAction, tOpts)}</div>}
        </>
    )
});

export default AnalysisDetails;