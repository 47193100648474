import UiModel from './ui-model';

const missedOpportunityOutletModel = new UiModel({
    title: "Missed Opportunity Market",
    defaultSort: 'MissedOpportunityCases DESC',
    idProperty: 'MissedOpportunityOutletId',
    readOnly: true,
    preferenceId: 'playbookMissedOpportunityOutlet',
    updateTitle: false,
    hideBackButton: true,
    addModifiedByColumn: false,
    addModifiedOnColumn: false,
    addCreatedOnColumn: false,
    addCreatedByColumn: false,
    pivotAPI: 'MissedOpportunityOutlet.ashx',
    template: 'missed-opportunity-outlet-pivot',
    configFileName: 'missed-opportunity-outlet',
    api: 'MissedOpportunityOutlet',
    showPivotExportBtn: true,
    columns: [
        { field: "OutletName", label: "Outlet Name", type: 'string', width: 200 },
        { field: "OutletCode", label: "Outlet Code", type: 'string', width: 200 },
        { field: "Product", label: "Product", type: 'string', width: 250 },
        { field: "AssetSerialNumber", label: "Asset Serial Number", type: 'string', width: 220 },
        { field: "CasesPerFacing", label: "Cases Per Facings", type: 'number', width: 200 },
        { field: "CasePrice", label: "Case Price", type: 'number', width: 150 },
        { field: "TotalInStockDays", label: "Total InStock Days", type: 'number', width: 200 },
        { field: "OutofStockDays", label: "Out of Stock Days", type: 'number', width: 200 },
        { field: "TotalCasesSold", label: "Total Cases Sold", type: 'number', width: 150 },
        { field: "TotalUnitsSold", label: "Total Units Sold", type: 'number', width: 150 },
        { field: "TimesRefilled", label: "Times Refilled", type: 'number', width: 150 },
        { field: "CasesSoldInOneDay", label: "Average Daily Cases", type: 'number', width: 200 },
        { field: "MissedRevenue", label: "Missed Revenue", type: 'number', width: 150 },
        { field: "ActualRevenue", label: "Actual Revenue", type: 'number', width: 150 },
        { field: "PotentialRevenue", label: "Potential Revenue", type: 'number', width: 200 },
        { field: "MarketName", label: "Market Name", width: 200 },
        { field: "DistributorName", label: "Distributor Name", width: 200 },
        { field: "ChannelName", label: "Channel Name", width: 200 },
        { field: "ClassificationName", label: "Classification", width: 200 }
    ]
});

export default missedOpportunityOutletModel;