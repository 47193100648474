import { Grid, Typography } from "@mui/material";
import CustomTextChart from "./CustomTextChart";
import XPieChart from "./XPieChart";
import utils from "../../utils";
import { useEffect, useState } from "react";
import { TextComparator } from './TextComparator.js';
import constants from "../../utils/constants.js";
import ChartComponent from "../Chart/muix/index.js";
import LineComponent from "./LineComponent.js";
import CombinedBarLineChart from "../Chart/Recharts/CombinedBarLineChart.js";
import dayjs from "dayjs";
const comparisonTab = "2";

function handleFileSceneMultiFile({ answer, toggleStates, utils, top10, bottom10, classes, comparatorSelected, lookupData, tab, showImages, displayNameMapping }) {
    const isProductImage = (answer.type === "multiFile" || answer.type === "scene") && [1, 2, 3].includes(answer?.beforeAfterImage);
    const openPopUp = utils.openPopUp;
    let view;

    if (showImages[answer.id] !== undefined) {
        view = showImages[answer.id];
    } else {
        view = answer?.checkTargetPortfolio || answer?.checkTargetPortfolio === undefined ? constants.analysisText : constants.imagesText;
    }
    const toggleState = toggleStates[answer.id] || 'top10';
    const isComparator = tab === comparisonTab;
    const updateSeriesNames = (series, lookupData) => {
        return series.map(seriesObj => {
            const item = lookupData.find(item => item.LookupId === Number(seriesObj.name));
            return item ? { ...seriesObj, name: item.DisplayValue } : seriesObj;
        });
    };

    const updateCategoryNames = (categories, lookupData) => {
        return categories.map(category => {
            const item = lookupData.find(item => item.LookupId === Number(category));
            return item ? item.DisplayValue : category;
        });
    };

    if (comparatorSelected) {
        const fData = lookupData[comparatorSelected];
        answer.comparatorData.productDistribution.series = updateSeriesNames(answer.comparatorData.productDistribution.series, fData);
        answer.comparatorData.productCompliance.series = updateSeriesNames(answer.comparatorData.productCompliance.series, fData);
        answer.comparatorData.kpis.categories = updateCategoryNames(answer.comparatorData.kpis.categories, fData);
        answer.comparatorData.assortmentDepth.categories = updateCategoryNames(answer.comparatorData.assortmentDepth.categories, fData);
        answer.comparatorData.compliance.categories = updateCategoryNames(answer.comparatorData.compliance.categories, fData);
    }
    const xProductDistributionData = answer.comparatorData.productDistribution?.series?.map(item => {
        return {
            name: utils.getFormattedCategory({ category: item.name, lookupData, comparatorSelected }),
            data: item.data
        };
    });
    const xProductComplianceData = answer.comparatorData.productCompliance?.series?.map(item => {
        return {
            name: utils.getFormattedCategory({ category: item.name, lookupData, comparatorSelected }),
            data: item.data
        };
    });
    const stackedData = answer.comparatorData.kpis.series;
    const sosData = utils.matchLabels(answer.pieSeries, stackedData);
    const noAnalystics = !(answer?.productImages?.series?.length);
    const getCategories = (categories) => {
        let comparatorCategories = [];
        if (comparatorSelected) {
            const fData = lookupData[comparatorSelected];
            const nCategories = categories;
            comparatorCategories = nCategories.map(categoryStr => {
                const categoryIds = categoryStr.split(',');
                const displayValues = categoryIds.map(categoryId => {
                    const item = fData && fData.find(item => item.LookupId === Number(categoryId));
                    return item ? item.DisplayValue : categoryId;
                });
                return displayValues.join(', ');
            });
        }
        return comparatorCategories;
    }
    const pd = utils.getChartDimensions(xProductDistributionData.length || 0);
    const pc = utils.getChartDimensions(xProductComplianceData.length || 0);
    if (isComparator) {
        if (isProductImage) {
            return (
                <>
                    {!noAnalystics ? <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LineComponent
                                    categories={answer.comparatorData.productDistribution.categories}
                                    data={xProductDistributionData}
                                    xLabel={"Products"}
                                    multiple={true}
                                    title={"Product Distribution "}
                                    custom={true}
                                    margin={pd.margin}
                                    height={pd.height}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LineComponent
                                    categories={answer.comparatorData.productCompliance.categories}
                                    data={xProductComplianceData}
                                    xLabel={"Products"}
                                    multiple={true}
                                    title={"Product Distribution Compliance"}
                                    custom={true}
                                    margin={pc.margin}
                                    height={pc.height}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ChartComponent
                                    type="bar"
                                    series={sosData.map((entry) => ({
                                        type: 'bar',
                                        data: entry?.data || [],
                                        label: entry?.name || '',
                                        stack: 'common'
                                    }))}
                                    categories={getCategories(answer.comparatorData.kpis.categories || [])}
                                    xLabel={`${displayNameMapping[comparatorSelected]}`}
                                    title={"Share of Shelf"}
                                    yLabel={`No of responses`}
                                    custom={true}
                                    barLabel="value"
                                    isStacked={true}
                                    isSoS={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ChartComponent
                                    type="bar"
                                    series={answer.comparatorData.assortmentDepth.series.map((entry) => ({
                                        type: 'bar',
                                        data: entry?.data || [],
                                        label: entry?.name || '',
                                        stack: 'common'
                                    }))}
                                    categories={getCategories(answer.comparatorData.assortmentDepth.categories || [])}
                                    xLabel={`${displayNameMapping[comparatorSelected]}`}
                                    title={"Assortment Depth"}
                                    yLabel={`No of responses`}
                                    custom={true}
                                    barLabel="value"
                                    isStacked={true}
                                />
                            </Grid>
                            {
                                answer.comparatorData.compliance.series.map((seriesItem, index) => {
                                    const { name: catName, data: plotData } = seriesItem;
                                    if (!plotData || plotData.length === 0) {
                                        return null;
                                    }
                                    const categories = answer.comparatorData.compliance.categories;
                                    return (
                                        <Grid item xs={4} key={`${index}-${catName}`}>
                                            <ChartComponent
                                                type="bar"
                                                categories={categories || []}
                                                complianceValues={plotData || []}
                                                data={plotData || []}
                                                title={catName}
                                                isCompliance={true}
                                                series={utils.generateComplianceSeries(categories.length)}
                                                margin={{
                                                    left: 180
                                                }}
                                            />
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </> : (
                        <Grid container justifyContent="center">
                            <Grid item justifyContent={'center'}>
                                <Typography variant="body1" align="center">
                                    {constants.noAnalyticsText}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                    }
                </>)
        }
        else {
            return (
                <TextComparator
                    answer={answer}
                    lookupData={lookupData}
                    comparatorSelected={comparatorSelected}
                    classes={classes}
                    question={answer}
                    openPopUp={openPopUp}
                />
            )
        }
    } else {
        if (isProductImage) {
            const filteredSeries = answer.productImages.series.filter(item => item !== null);
            const sortedAndFilteredData = utils.getSortedData(filteredSeries || [], toggleState, 'y', top10, bottom10);
            const categories = answer?.complianceCategories;
            return view === constants.analysisText ? (
                <>
                    {/* Show analytics if run anlytics is also undefined for ald questionarries */}
                    {(answer.checkTargetPortfolio === undefined || answer.checkTargetPortfolio) && !noAnalystics ? (
                        <>
                            <CombinedBarLineChart
                                title="Product Distribution Chart"
                                subtitle="Distribution per Product"
                                categories={answer.productImages.categories}
                                data={sortedAndFilteredData}
                                xLabel="Products"
                                yLabel="Distribution Value"
                                align="center"
                                barDataKey="ProductDistribution"
                                lineDataKey="ProductDistributionCompliance"
                                toggleState={toggleState}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={4} style={{ textAlign: "center" }}>
                                    <div className={classes.assortment}>
                                        {"Assortment Depth"}
                                    </div>
                                    <div className={classes.assortmentDetails}>
                                        {"(How many different products from our portfolio can shoppers find?)"}
                                    </div>
                                    <div style={{ fontSize: "3em" }}>
                                        {`[${answer?.AssortmentDepth?.toFixed(2) || 0}]`}
                                    </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <XPieChart
                                        title="Share of Shelf"
                                        subtitle="Distribution of SoS per Product"
                                        data={answer.pieSeries}
                                        align="center"
                                        size="40%"
                                        custom={true}
                                    />
                                </Grid>
                            </Grid>
                            <ChartComponent
                                type="bar"
                                layout="horizontal"
                                categories={categories || []}
                                complianceValues={answer?.complianceSeries || []}
                                data={answer?.complianceSeries || []}
                                isCompliance={true}
                                series={utils.generateComplianceSeries(categories.length)}
                                margin={{
                                    left: 180
                                }}
                            />
                        </>
                    ) : (
                        <Grid container justifyContent="center">
                            <Grid item justifyContent={'center'}>
                                <Typography variant="body1" align="center">
                                    {constants.noAnalyticsText}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </>
            ) : (
                <RenderProductImage answer={answer} toggleState={toggleState} top10={top10} bottom10={bottom10} />
            );


        } else {
            const toggleState = toggleStates[answer.id] || 'top10';
            return <RenderProductImage answer={answer} toggleState={toggleState} top10={top10} bottom10={bottom10} />;
        }
    }
}

function handleTextTextArea({ answer, toggleStates, utils, top10, bottom10, classes, checkSentenceLength, handleExpandClick, expandedText, comparatorSelected, lookupData, tab }) {
    const toggleState = toggleStates[answer.id] || 'top10';
    const sortedAndSlicedData = utils.getSortedData(answer.sortedText, toggleState, 'y', top10, bottom10);
    const openPopUp = utils.openPopUp;
    const isComparator = tab === comparisonTab;
    if (isComparator) {
        return (
            <TextComparator
                answer={answer}
                lookupData={lookupData}
                comparatorSelected={comparatorSelected}
                classes={classes}
                question={answer}
                openPopUp={openPopUp}
            />
        )
    } else {
        return (
            <CustomTextChart
                classes={classes}
                answer={answer}
                sortedData={sortedAndSlicedData}
                checkSentenceLength={checkSentenceLength}
                handleExpandClick={handleExpandClick}
                chartData={answer}
                expandedText={expandedText}
            />
        );
    }
}

const RenderProductImage = ({ answer, toggleState, top10, bottom10 }) => {
    const [maxHeight, setMaxHeight] = useState(55); // Initial max height in vh
    useEffect(() => {
        const handleResize = () => {
            const newMaxHeight = Math.min(55, window.innerHeight * 0.8);
            setMaxHeight(newMaxHeight);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const sortedImageData = utils.getSortedData(answer.imageData, toggleState, 'y', top10, bottom10);
    return (
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", maxHeight: `${maxHeight}vh`, overflowY: "auto" }}>
            {sortedImageData.map((imageData, index) => {
                const imageUrl = imageData.url;
                return (
                    <div key={index} style={{ margin: "5px", display: 'inline-block', width: 'calc(10% - 10px)', textAlign: 'center' }}>
                        <img
                            src={imageUrl}
                            alt={`Image ${index}`}
                            style={{ width: "100%", height: "auto", margin: '0 auto' }}
                            className="survey-view-image"
                            onClick={(e) => utils.openPopUp(e, imageUrl, index, sortedImageData)}
                            onKeyDown={(e) => utils.openPopUp(e, imageUrl, index, sortedImageData)}
                        />
                    </div>
                );
            })}
        </div>
    );
}


export const answerType = {
    radio: function ({ answer, toggleStates, utils, top10, bottom10, isCustomOption, classes, checkSentenceLength, handleExpandClick, expandedText, lookupData, tab, displayNameMapping, comparatorSelected }) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const sortedAndSlicedData = utils.getSortedData(answer.series[0]?.data, toggleState, 'y', top10, bottom10);
        const isComparator = tab === comparisonTab;
        const plottingData = {
            ...answer.series[0],
            data: sortedAndSlicedData
        };
        const dataWithPercentages = sortedAndSlicedData.map(dataPoint => ({
            ...dataPoint,
            percentage: ((dataPoint.value / answer.totalCount) * 100).toFixed(2)
        }));
        let comparatorCategories = [];
        if (comparatorSelected) {
            const fData = lookupData[comparatorSelected];
            const nCategories = answer.comparatorData.categories;
            comparatorCategories = nCategories.map(categoryStr => {
                const categoryIds = categoryStr.split(',');
                const displayValues = categoryIds.map(categoryId => {
                    const item = fData && fData.find(item => item.LookupId === Number(categoryId));
                    return item ? item.DisplayValue : categoryId;
                });
                return displayValues.join(', ');
            });
        }
        const categories = dataWithPercentages.map(dataPoint => {
            const nameParts = dataPoint.label.split('-');
            return nameParts[0];
        });
        const xBarData = plottingData?.data.map(item => item.value);
        const stackedData = answer.comparatorData?.series;
        const rk = utils.getChartDimensions(stackedData.length || 0);
        if (isComparator) {
            return (
                <ChartComponent
                    type="bar"
                    series={stackedData.map((entry) => ({
                        type: 'bar',
                        data: entry?.data || [],
                        label: entry?.name || '',
                        stack: 'common'
                    }))}
                    categories={comparatorCategories}
                    xLabel={`${displayNameMapping[comparatorSelected]}`}
                    yLabel={`No of responses`}
                    custom={true}
                    barLabel="value"
                    isStacked={true}
                    margin={rk.margin}
                    height={rk.height}
                />
            )
        } else {
            if (sortedAndSlicedData.length > 5) {
                return (
                    <ChartComponent
                        type="bar"
                        series={[{ type: 'bar', data: xBarData }]}
                        categories={categories}
                        xLabel={`Option Values`}
                        yLabel={`No of responses`}
                    />
                );
            } else if (isCustomOption) {
                return (
                    <CustomTextChart
                        classes={classes}
                        answer={answer}
                        sortedData={answer.sortedText}
                        checkSentenceLength={checkSentenceLength}
                        handleExpandClick={handleExpandClick}
                        chartData={answer}
                        expandedText={expandedText}
                    />
                );
            } else {
                return (
                    <XPieChart
                        subtitle={answer.subtitle}
                        data={dataWithPercentages}
                    />
                );
            }
        }
    },
    checkbox: function ({ answer, toggleStates, utils, top10, bottom10, isCustomOption, classes, checkSentenceLength, handleExpandClick, expandedText, format, comparatorSelected, lookupData, tab }) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const sortedAndSlicedData = utils.getSortedData(answer.series[0]?.data, toggleState, 'y', top10, bottom10);
        const isComparator = tab === comparisonTab;
        const plottingData = {
            ...answer.series[0],
            data: sortedAndSlicedData
        };
        const dataWithPercentages = sortedAndSlicedData.map(dataPoint => ({
            ...dataPoint,
            percentage: ((dataPoint.y / answer.totalCount) * 100).toFixed(2)
        }));
        const categories = dataWithPercentages.map(dataPoint => {
            const nameParts = dataPoint.label.split('-');
            return nameParts[0];
        });
        if (comparatorSelected) {
            const fData = lookupData[comparatorSelected] || [];
            const nSeries = answer?.comparatorData?.series || [];
            answer.comparatorData.series = nSeries.map(seriesObj => {
                const item = fData.find(item => item.LookupId === Number(seriesObj.name));
                if (item) {
                    return {
                        ...seriesObj,
                        name: item.DisplayValue
                    };
                } else {
                    return seriesObj;
                }
            });
        }
        const xBarData = plottingData?.data.map(item => item.value)
        const xCompData = answer.comparatorData?.series?.map(item => {
            return {
                name: utils.getFormattedCategory({ category: item.name, lookupData, comparatorSelected }),
                data: item.data
            };
        });
        const ck = utils.getChartDimensions(xCompData.length || 0);
        if (isComparator) {
            return (
                <LineComponent
                    data={xCompData}
                    categories={answer.comparatorData.categories}
                    multiple={true}
                    custom={true}
                    margin={ck.margin}
                    height={ck.height}
                />
            );
        } else {
            if (sortedAndSlicedData.length > 5) {
                return (
                    <ChartComponent
                        type="bar"
                        series={[{ type: 'bar', data: xBarData }]}
                        categories={categories}
                        xLabel={`Option Values`}
                        yLabel={`No of responses`}
                    />
                );
            } else if (isCustomOption && (answer.sortedText.length > 0)) {
                return (
                    <CustomTextChart
                        classes={classes}
                        answer={answer}
                        sortedData={answer.sortedText}
                        checkSentenceLength={checkSentenceLength}
                        handleExpandClick={handleExpandClick}
                        chartData={answer}
                        expandedText={expandedText}
                    />
                );
            } else {
                return (
                    <XPieChart
                        subtitle={answer.subtitle}
                        data={dataWithPercentages}
                    />
                );
            }
        }
    },
    date: function ({ answer, format, tab, lookupData, comparatorSelected }) {
        const isComparator = tab === comparisonTab;
        if (comparatorSelected) {
            if (lookupData && lookupData.hasOwnProperty(comparatorSelected)) {
                const fData = lookupData[comparatorSelected];
                const nSeries = Array.isArray(answer.comparatorData.series) ? answer.comparatorData.series : [];
                answer.comparatorData.series = nSeries.map(seriesObj => {
                    const item = fData.find(item => item.LookupId === Number(seriesObj.name));
                    if (item) {
                        return {
                            ...seriesObj,
                            name: item.DisplayValue
                        };
                    } else {
                        return seriesObj;
                    }
                });
            }
        }
        const xCompData = answer.comparatorData?.series?.map(item => {
            return {
                name: utils.getFormattedCategory({ category: item.name, lookupData, comparatorSelected }),
                data: item.data
            };
        });
        const initialCategories = utils.sortDates(answer.comparatorData.categories, format);
        const categories = initialCategories.map(item => dayjs(item).format(format));
        const dk = utils.getChartDimensions(xCompData.length || 0);
        const totalInitialCategories = utils.sortDates(answer.sortedDateData.categories, format);		
        const totalCategories = totalInitialCategories.map(item => dayjs(item).format(format));
        if (isComparator) {
            return (
                <LineComponent
                    data={xCompData}
                    categories={categories}
                    isDate={true}
                    multiple={true}
                    custom={true}
                    margin={dk.margin}
                    height={dk.height}
                    format={format}
                />
            );
        } else {
            return (
                <LineComponent
                    data={answer.sortedDateData.series}
                    categories={totalCategories}
                    isDate={true}
                    format={format}
                />
            )
        }
    },

    number: function ({ answer, toggleStates, utils, top10, bottom10, tab, lookupData, comparatorSelected }) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const sortedAndSlicedData = utils.getSortedData(answer.series.data, toggleState, 'y', top10, bottom10);
        const isComparator = tab === comparisonTab;
        const plottingData = {
            ...answer.series,
            data: sortedAndSlicedData
        };
        if (comparatorSelected) {
            const fData = lookupData[comparatorSelected];
            const nSeries = answer.comparatorData.series;
            answer.comparatorData.series = nSeries.map(seriesObj => {
                const item = fData.find(item => item.LookupId === Number(seriesObj.name));
                if (item) {
                    return {
                        ...seriesObj,
                        name: item.DisplayValue
                    };
                } else {
                    return seriesObj;
                }
            });
        }

        const xBarData = plottingData?.data.map(item => item.value);
        const cat = plottingData?.data.map(item => item.label);
        const xCompData = answer.comparatorData?.series?.map(item => {
            return {
                name: utils.getFormattedCategory({ category: item.name, lookupData, comparatorSelected }),
                data: item.data
            };
        });
        if (isComparator) {
            return (
                <LineComponent
                    data={xCompData}
                    categories={answer.comparatorData.categories}
                    multiple={true}
                    custom={true}
                />
            )
        } else {
            return (
                <ChartComponent
                    type="bar"
                    series={[{ type: 'bar', data: xBarData }]}
                    categories={cat}
                    xLabel={`Option Values`}
                    yLabel={`No of responses`}
                />
            );
        }
    },
    barcode: function ({ answer, toggleStates, utils, top10, bottom10, classes, checkSentenceLength, handleExpandClick, expandedText, comparatorSelected, lookupData, tab }) {
        const isValidatedAssetBarcode = answer.validateFromAsset || Array.isArray(answer?.items) || (answer?.title === constants.genSection);
        const isComparator = tab === comparisonTab;
        const compareRex = /^\d+$/;
        if (isValidatedAssetBarcode) {
            if (comparatorSelected) {
                const fData = lookupData[comparatorSelected];
                const nSeries = answer.comparatorData.series;
                answer.comparatorData.series = nSeries.map(seriesObj => {
                    const item = fData.find(item => item.LookupId === Number(seriesObj.name));
                    if (item) {
                        return {
                            ...seriesObj,
                            name: item.DisplayValue || ''
                        };
                    } else if (compareRex.test(seriesObj.name)) {
                        return {
                            ...seriesObj,
                            name: `Blank`
                        };
                    }
                    else {
                        return seriesObj;
                    }
                });
            }
            const sortedCategories = answer.series[0].data.map(item => item.name);
            const xData = answer.series[0].data.map(item => item.y);
            const xBarData = answer.series?.[0].data.map(item => item.y);
            if (isComparator) {
                return (
                    <LineComponent
                        categories={answer.comparatorData.categories}
                        data={answer.comparatorData?.series}
                        multiple={true}
                        custom={true}
                        isBarCode={true}
                    />
                )
            } else {
                return (
                    <ChartComponent
                        type="bar"
                        series={[{ type: 'bar', data: xBarData }]}
                        categories={sortedCategories}
                        xLabel={`Option Values`}
                        yLabel={`No of responses`}
                    />
                );
            }

        } else {
            const toggleState = toggleStates[answer.id] || 'top10';
            const dataForSorting = answer.sortedText.map(([name, count]) => ({ y: count, name }));
            const sortedAndSlicedData = utils.getSortedData(dataForSorting, toggleState, 'y', top10, bottom10);
            const finalSortedAndSlicedData = sortedAndSlicedData.map(item => [item.name, item.y]);
            const openPopUp = utils.openPopUp;
            const isComparator = tab === comparisonTab;
            if (isComparator) {
                return (
                    <TextComparator
                        answer={answer}
                        lookupData={lookupData}
                        comparatorSelected={comparatorSelected}
                        classes={classes}
                        question={answer}
                        openPopUp={openPopUp}
                    />
                )
            } else {
                return (
                    <CustomTextChart
                        classes={classes}
                        answer={answer}
                        sortedData={finalSortedAndSlicedData}
                        checkSentenceLength={checkSentenceLength}
                        handleExpandClick={handleExpandClick}
                        chartData={answer}
                        expandedText={expandedText}
                    />
                );
            }
        }
    },
    multiFileStitch: function ({ answer, toggleStates, top10, bottom10, showImages, comparatorSelected, lookupData, tab }) {
        let view;
        if (showImages[answer.id] !== undefined) {
            view = showImages[answer.id];
        } else {
            view = answer?.validateFromPosMaterial || answer?.validateFromPosMaterial === undefined ? constants.analysisText : constants.imagesText;
        }

        const toggleState = toggleStates[answer.id] || 'top10';
        const isComparator = tab === comparisonTab;
        const updateSeriesNames = (series, lookupData) => {
            return series.map(seriesObj => {
                const item = lookupData.find(item => item.LookupId === Number(seriesObj.name));
                return item ? { ...seriesObj, name: item.DisplayValue } : seriesObj;
            });
        };

        if (comparatorSelected) {
            const fData = lookupData[comparatorSelected];
            answer.comparatorData.posData.series = updateSeriesNames(answer.comparatorData.posData.series, fData);
            answer.comparatorData.posStoreData.series = updateSeriesNames(answer.comparatorData.posStoreData.series, fData);
        }
        const posData = answer?.posImages?.barSeries?.[0]?.data?.map(item => item.y) || [];
        const posByStore = answer?.posByStoreImages?.barSeries?.[0]?.data?.map(item => item.y) || [];
        if (isComparator) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <LineComponent
                            categories={answer.comparatorData.posData.categories}
                            data={answer.comparatorData?.posData.series}
                            multiple={true}
                            custom={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LineComponent
                            categories={answer.comparatorData.posStoreData.categories}
                            data={answer.comparatorData?.posStoreData.series}
                            multiple={true}
                            custom={true}
                        />
                    </Grid>
                </Grid>
            )
        } else {
            return view === constants.analysisText ? (
                answer?.validateFromPosMaterial === undefined || answer?.validateFromPosMaterial ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <ChartComponent
                                type="bar"
                                series={[{ type: 'bar', layout: 'horizontal', data: posData }]}
                                categories={answer.posImages.categories}
                                xLabel="Promotions"
                                // yLabel="POSM Distribution"
                                trigger="axis"
                                layout="horizontal"
                                margin={{ left: 100 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ChartComponent
                                type="bar"
                                series={[{ type: 'bar', layout: 'horizontal', data: posByStore }]}
                                categories={answer.posByStoreImages.categories}
                                xLabel="Promotion Count"
                                // yLabel="Outlets"
                                trigger="axis"
                                layout="horizontal"
                                margin={{ left: 100 }}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent="center">
                        <Grid item justifyContent={'center'}>
                            <Typography variant="body1" align="center">
                                {constants.noAnalyticsText}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            ) : (
                <RenderProductImage answer={answer} toggleState={toggleState} top10={top10} bottom10={bottom10} />
            );
        }
    },
    select: function ({ answer, toggleStates, utils, top10, bottom10, format, comparatorSelected, lookupData, tab, displayNameMapping }) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const sortedAndSlicedData = utils.getSortedData(answer.series[0]?.data, toggleState, 'y', top10, bottom10);
        const isComparator = tab === comparisonTab;
        const singleChoice = answer.singleChoice;
        const multipleChoice = answer.multipleChoice;
        let comparatorCategories = [];
        if (comparatorSelected) {
            const fData = lookupData[comparatorSelected] || [];
            const nCategories = answer.comparatorData.categories || [];
            comparatorCategories = nCategories.map(categoryStr => {
                const categoryIds = categoryStr.split(',');
                const displayValues = categoryIds.map(categoryId => {
                    const item = fData.find(item => item.LookupId === Number(categoryId));
                    return item ? item.DisplayValue : categoryId;
                });
                return displayValues.join(', ');
            });
            const nSeries = answer?.comparatorData?.series || [];
            answer.comparatorData.series = nSeries.map(seriesObj => {
                const item = fData.find(item => item.LookupId === Number(seriesObj.name));
                if (item) {
                    return {
                        ...seriesObj,
                        name: item.DisplayValue
                    };
                } else {
                    return seriesObj;
                }
            });
        }
        const dataWithPercentages = sortedAndSlicedData.map(dataPoint => ({
            ...dataPoint,
            percentage: ((dataPoint.y / answer.totalCount) * 100).toFixed(2)
        }));
        const xBarData = answer?.series?.[0]?.data.map(item => item.value);
        const xCompData = answer.comparatorData?.series?.map(item => {
            return {
                name: utils.getFormattedCategory({ category: item.name, lookupData, comparatorSelected }),
                data: item.data
            };
        });
        const dropdownData = answer.comparatorData?.series;
        const margin = { top: 105 };
        let height = 450;
        if (isComparator) {
            if (singleChoice) {
                return (
                    <ChartComponent
                        type="bar"
                        series={dropdownData.map((entry) => ({
                            type: 'bar',
                            data: entry?.data || [],
                            label: entry?.name || '',
                            stack: 'common'
                        }))}
                        categories={comparatorCategories || []}
                        xLabel={`${displayNameMapping[comparatorSelected]}`}
                        yLabel={`No of responses`}
                        custom={true}
                        barLabel="value"
                        isStacked={true}
                        margin={margin}
                        height={height}
                    />
                )
            }
            if (multipleChoice) {
                return (
                    <LineComponent
                        categories={answer.comparatorData?.categories || []}
                        data={xCompData}
                        multiple={true}
                        custom={true}
                    />
                );
            }
        } else {
            if (sortedAndSlicedData.length > 5) {
                return (
                    <ChartComponent
                        type="bar"
                        series={[{ type: 'bar', data: xBarData }]}
                        categories={answer.categories}
                        xLabel={`Option Values`}
                        yLabel={`No of responses`}
                    />
                );
            } else {
                return (
                    <XPieChart
                        subtitle={answer.subtitle}
                        data={dataWithPercentages}
                    />
                );
            }
        }
    },
    multiFile: handleFileSceneMultiFile,
    file: handleFileSceneMultiFile,
    scene: handleFileSceneMultiFile,
    text: handleTextTextArea,
    textarea: handleTextTextArea
};